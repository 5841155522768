<template>
  <div>
    <table-page :config="config" />
  </div>
</template>

<script>
import TablePage from "@/components/TablePage";

export default {
  name: "serviceItems",
  components: {
    TablePage
  },
  data() {
    return {
      config: {
        /* 表格是否可多选 */
        dataUrl: '/clientUser/internationalSchool/honorQualification/list?schoolId=' + this.$route.query.id,
        belongTo: '当前学校：' + this.$route.query.other,
        filter: {
          // initUrl: '/agency/institutionsEmployee/list/get',
          controls: []
        },
        columns: [
          {
            key: 'name',
            title: '荣誉资质名',
            width: '100px',
          },
          {
            key: 'sort',
            title: '排序',
            width: '100px',
          },
          {
            key: 'name',
            title: '操作',
            type: 'actions',
            fixed: 'right',
            width: document.documentElement.clientWidth <= 768 ? '80px' : '100px'
          }
        ],

        /* 表格排序 */
        sorter: {
          /* 可排序列 */
          sortableColumns: [''],
          /* 默认排序 */
          defaultSort: ''
        },
        checkbox: true,
        actions: [
          {
            key: 'edit',
            text: '新建荣誉资质',
            type: 'form',
            permission: '/clientUser/internationalSchool/honorQualification/add',
            config: {
              color: 'primary',
              title: '新建荣誉资质',
              submitUrl: "/clientUser/internationalSchool/honorQualification/add",
              submitText: '保存',
              controls: [
                {
                  key: 'picture',
                  label: '封面图片',
                  required: true,
                  tips: "上传比例为 xxx:xxx，大小不超过3mb的图片",
                  type: 'file',
                  config: {
                    uploadUrl: '/api/clientUser/institutionalShop/addUpload',
                    format: "image",
                    accept: ["jpeg", "png", 'jpg'],
                    maxSize: 1024*3000
                  }
                },
                {},
                {
                  key: 'internationalSchoolId',
                  label: '学校名',
                  type: 'select',
                  required: true,
                  disabled: true,
                  defaultValue: this.$route.query.id,
                  config: {
                    options: [
                      {
                        value: this.$route.query.id,
                        text: this.$route.query.other,
                      }
                    ]
                  }
                },
                {
                  key: 'name',
                  label: '项目名',
                  type: 'text',
                  required: true,
                },
                {
                  key: 'sort',
                  label: '排序',
                  type: 'numeric',
                  config: {
                    format: 'sort'
                  }
                },
                {}
              ]
            }
          },
          {
            key: 'edit',
            text: '删除',
            icon: 'plus',
            type: 'dialog',
            isSelected: true,  // 是否必勾选
            determine: true,  // 是否需要判定条
            permission: '/clientUser/internationalSchool/honorQualification/delete',
            modalObj: (rowData, tableData) => {
              return `您共选择 ${rowData.length} 条荣誉资质，是否全部删除? `
            },
            config: {
              title: '删除成功',
              submitUrl: '/clientUser/internationalSchool/honorQualification/delete',
              submitText: '确认',
              controls: [
                {
                  key: 'internationalSchoolId',
                  label: '学校名',
                  type: 'select',
                  disabled: true,
                  defaultValue: this.$route.query.id,
                  config: {
                    options: [
                      {
                        value: this.$route.query.id,
                        text: this.$route.query.other,
                      }
                    ]
                  }
                },
              ]
            }
          },
        ],
        /* 表格行操作 */
        rowActions: [
          {
            key: 'edit',
            text: '编辑',
            type: 'form',
            permission: '/clientUser/internationalSchool/honorQualification/edit',
            config: {
              color: 'primary',
              title: '编辑',
              initUrl: "/clientUser/internationalSchool/honorQualification/edit/get",
              submitUrl: "/clientUser/internationalSchool/honorQualification/edit",
              submitText: '保存',
              controls: [
                {
                  key: 'picture',
                  label: '封面图片',
                  required: true,
                  tips: "上传比例为 xxx:xxx，大小不超过3mb的图片",
                  type: 'file',
                  config: {
                    uploadUrl: '/api/clientUser/institutionalShop/addUpload',
                    format: "image",
                    accept: ["jpeg", "png", 'jpg'],
                    maxSize: 1024*3000
                  }
                },
                {},
                {
                  key: 'internationalSchoolId',
                  label: '学校名',
                  type: 'select',
                  required: true,
                  disabled: true,
                  defaultValue: this.$route.query.id,
                  config: {
                    options: [
                      {
                        value: this.$route.query.id,
                        text: this.$route.query.other,
                      }
                    ]
                  }
                },
                {
                  key: 'name',
                  label: '项目名',
                  type: 'text',
                  required: true,
                },
                {
                  key: 'sort',
                  label: '排序',
                  type: 'numeric',
                  config: {
                    format: 'sort'
                  }
                },
                {
                  key: 'version',
                  label: '',
                },
              ]
            }
          }
        ]
      }
    }
  },
  async mounted() {
  },
  onUnmounted() {

  },
  computed: {

  },

  methods: {

  }
}
</script>

<style scoped lang="scss"></style>